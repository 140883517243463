import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

export const Canvas = styled.canvas`
  position: absolute;
  border-radius: inherit;
  width: 100%;
  height: 100%;
`;

export const BackImage = styled.img`
  width: 100%;
  display: block;
  border-radius: inherit;
`;
