import React from 'react';
import * as S from './CanvasScratch.styles';
import FunnelContext from '../../../context/FunnelContext';

class CanvasScratch extends React.Component {
  static contextType = FunnelContext;

  constructor(props) {
    super(props);

    let width = props.width;
    let height = props.height;

    if (props.width > window.innerWidth) {
      const ratio = (window.innerWidth - 31) / props.width;
      width = props.width * ratio;
      height = props.height * ratio;
    }

    this.state = {
      isDrawing: false,
      startX: 0,
      startY: 0,
      width: width,
      height: height,
      redirecting: false
    };

    this.canvasRef = React.createRef();
    this.backImageRef = React.createRef();
  }

  forceUpdate = () => {
    const backImage = this.backImageRef.current;
    this.setState({
      width: backImage.width,
      height: backImage.height
    });
  };

  componentDidMount = () => {
    const canvas = this.canvasRef.current;

    canvas.addEventListener('resize', this.resize);
    canvas.addEventListener('mousedown', this.scratchStart);
    canvas.addEventListener('mousemove', this.scratch);
    canvas.addEventListener('mouseup', this.scratchEnd);

    canvas.addEventListener('touchstart', this.scratchStart, false);
    canvas.addEventListener('touchmove', this.scratch, false);
    canvas.addEventListener('touchend', this.scratchEnd, false);
    canvas.addEventListener('touchcancel', this.scratchEnd, false);

    this.draw();
  };

  resize() {
    const backImage = this.backImageRef.current;
    this.setState({
      width: backImage.width,
      height: backImage.height
    });
  }

  draw() {
    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');

    context.width = canvas.offsetWidth;
    context.height = canvas.offsetHeight;

    context.fillStyle = this.props.frontBgColor ?? '#FFF';
    context.fillRect(0, 0, canvas.offsetWidth, canvas.offsetHeight);
    context.lineWidth = 50;
    context.lineJoin = 'round';

    this.toDataURL(this.props.frontImage).then(dataUrl => {
      this.insertScratchImage(dataUrl, context);
    });
  }

  toDataURL = url => {
    return fetch(url)
      .then(response => response.blob())
      .then(
        blob =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
  };

  insertScratchImage = (scratchImage, context) => {
    const frontImage = new Image();
    frontImage.src = scratchImage;
    frontImage.crossOrigin = 'Anonymous';
    frontImage.onload = () => {
      context.drawImage(frontImage, 0, 0, this.state.width, this.state.height);
    };
  };

  scratchStart = e => {
    if (!this.canvasRef.current) {
      return;
    }

    if (this.state.isDrawing) {
      return;
    }

    if (this.state.redirecting) {
      return;
    }

    let layerX = 0,
      layerY = 0;

    if (e.type === 'touchstart') {
      e.preventDefault();
      e.stopPropagation();
      const rect = e.target.getBoundingClientRect();
      layerX = e.targetTouches[0].pageX - rect.left;
      layerY = e.targetTouches[0].pageY - rect.top;
    } else {
      layerX = e.layerX;
      layerY = e.layerY;
    }

    this.setState({
      isDrawing: true,
      startX: layerX,
      startY: layerY
    });
  };

  scratch = e => {
    let layerX = 0,
      layerY = 0;
    if (e.type === 'touchmove') {
      e.preventDefault();
      e.stopPropagation();
      const rect = e.target.getBoundingClientRect();
      layerX = e.targetTouches[0].pageX - rect.left;
      layerY = e.targetTouches[0].pageY - rect.top;
    } else {
      layerX = e.layerX;
      layerY = e.layerY;
    }

    if (!this.canvasRef.current) {
      return;
    }

    if (!this.state.isDrawing) {
      return;
    }

    if (this.state.redirecting) {
      return;
    }

    const context = this.canvasRef.current.getContext('2d');
    context.globalCompositeOperation = 'destination-out';
    context.beginPath();
    context.moveTo(this.state.startX, this.state.startY);
    context.lineTo(layerX, layerY);
    context.closePath();
    context.stroke();

    this.setState({
      startX: layerX,
      startY: layerY
    });

    this.handleRedirect(this.getFilledInPixels(context));
  };

  scratchEnd = e => {
    if (e.type === 'touchend' || e.type === 'touchcancel') {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!this.canvasRef.current) {
      return;
    }

    if (!this.state.isDrawing) {
      return;
    }

    if (this.state.redirecting) {
      return;
    }

    this.setState({
      isDrawing: false
    });
  };

  getFilledInPixels(ctx) {
    const scratchSize = this.props.scratchSize ?? 50;
    const stride = scratchSize;
    const pixels = ctx.getImageData(0, 0, this.state.width, this.state.height);
    const pdata = pixels.data;
    const l = pdata.length;
    const total = l / stride;
    let count = 0;

    // Iterate over all pixels
    for (let i = (count = 0); i < l; i += stride) {
      if (parseInt(pdata[i]) === 0) {
        count++;
      }
    }

    return Math.round((count / total) * 100);
  }

  handleRedirect = s => {
    if (this.props.redirect) {
      if (s > this.props.redirectOn && !this.state.redirecting) {
        this.setState({ redirecting: true, isDrawing: false });
        window.location.href = this.props.redirectUrl;
      }
    } else {
      if (this.props.nextLayer) {
        if (s > this.props.nextLayerOn && !this.state.redirecting) {
          this.setState({ redirecting: true, isDrawing: false });
          const {
            currentLayer,
            setCurrentLayer,
            previousLayer,
            setPreviousLayer
          } = this.context;

          setPreviousLayer([...previousLayer, currentLayer]);
          setCurrentLayer(this.props.nextLayerId);
        }
      }
    }
  };

  render() {
    return (
      <S.Container>
        <S.Canvas
          id="canvas"
          ref={this.canvasRef}
          width={this.state.width}
          height={this.state.height}
        />
        <S.BackImage
          id="scratch-bg-image"
          src={this.props.backImage}
          ref={this.backImageRef}
        />
      </S.Container>
    );
  }
}

export default CanvasScratch;
